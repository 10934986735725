import { BuyerFavoriteProductSwitch } from 'components/buyer/products/BuyerFavoriteProductSwitch';
import { SellerFavoriteProductSwitch } from 'components/seller/products/SellerFavoriteProductSwitch';
import { EditableImageWithFallback } from 'components/shared/controls/EditableImageWithFallback/EditableImageWithFallback';

import { ImageWithFallback } from 'components/shared/ImageWithFallback';
import { FullScreenLoader } from 'components/shared/Loaders/FullScreenLoader';
import { AppContext } from 'contexts/user/AppContext';
import NoProductImage from 'media/ElecZap_new_logo_grayscale_cropped.png';
import logov2 from 'media/ElecZap_new_logo_text.png';
import { GroupedProductModel } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { LoginResponseUserModel } from 'models/identity/LoginResponseUserModel';
import { ProductDetailsModel } from 'models/shared/product/ProductDetailsModel';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Options } from 'react-to-pdf';

type PropsType = {
  products: ProductDetailsModel[];
  isAddToCartVisible?: boolean;
  viewType: 'Seller' | 'Buyer';
  handleImageUpload?: (file: File) => Promise<void>;
  handleImageDelete?: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  toPDF?: (option?: Options) => void;
};

export const ProductDetailsHeaderPrintable = ({
  products,
  viewType,
  isAddToCartVisible = false,
  handleImageUpload,
  handleImageDelete,
  toPDF,
}: PropsType) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation('components');

  const authUser = useAuthUser();
  const currentUser = authUser() as LoginResponseUserModel;

  const { appState, appContextDispatch } = useContext(AppContext);

  useEffect(() => {
    if (viewType === 'Buyer') {
      const product: GroupedProductModel = {
        productCode: products[0].code,
        products: products,
      };
      appContextDispatch({
        type: 'BUYER_SET_CURRENT_PRODUCT_LIST',
        currentProducts: [product],
      });
    }
  }, [appContextDispatch, products, viewType]);

  const isSellerSelectedForGivenProduct = (
    product: ProductDetailsModel
  ): boolean => {
    return (
      appState.buyerCurrentSuppliers?.findIndex(
        (supplier) =>
          supplier.value === product.company.id && supplier.isSelected
      ) !== -1 ||
      appState.buyerCurrentSuppliers.findIndex(
        (v) =>
          v.isActive &&
          v.isSelected &&
          v.subSellers?.some(
            (subSeller) =>
              product.company.id === subSeller.value && subSeller.isSelected
          )
      ) !== -1
    );
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Card.Header className='p-1'>
        <Row className='flex-nowrap width-max-content'>
          <Col className='multiple-seller-card-header-max-width'>
            <Row>
              <Col lg={2} className='d-print-none'></Col>
              <Col>
                <h2 className='ms-0 mb-0'>{products[0].code}</h2>

                <small>{products[0].manufacturerName}</small>
              </Col>
            </Row>
          </Col>
          {products.map(
            (product) =>
              (viewType === 'Seller' ||
                isSellerSelectedForGivenProduct(product)) && (
                <Col className='d-flex justify-content-between ms-3 multiple-seller-card-product-max-width'>
                  <span className='d-flex'>
                    <h4 className='ms-4'>
                      {product.company.shortName ?? product.company.name}
                    </h4>
                  </span>
                  <img
                    src={logov2}
                    alt='ElecZap'
                    className='logo-product-card-header float-end'
                  ></img>
                  <div />
                </Col>
              )
          )}
        </Row>
        <Row className='flex-nowrap width-max-content '>
          <Col className='multiple-seller-card-header-max-width'></Col>

          {products &&
            products.map(
              (product) =>
                (viewType === 'Seller' ||
                  isSellerSelectedForGivenProduct(product)) && (
                  <Col className='text-center ms-3 multiple-seller-card-product-max-width'>
                    {viewType === 'Buyer' ? (
                      <ImageWithFallback
                        alt={product.code}
                        className='img-Aporduct'
                        src={product.image}
                        fallback={NoProductImage}
                      />
                    ) : (
                      <EditableImageWithFallback
                        alt={product.code}
                        className='img-Aporduct overlay-image'
                        src={product.image}
                        fallback={NoProductImage}
                        maxFileSizekB={500}
                        accept={'.png, .jpg, .jpeg, .gif, .bmp, .tif, .tiff'}
                        uploadProductImageRequirementsText={t(
                          'editableImageWithFallback.lbl_UploadProductImageRequirements',
                          { maxFileSizeKb: 500 }
                        )}
                        handleImageUpload={handleImageUpload}
                        handleImageDelete={handleImageDelete}
                      />
                    )}
                  </Col>
                )
            )}
        </Row>
        <Row className='p-3 flex-nowrap width-max-content'>
          <Col className='multiple-seller-card-header-max-width text-end h5'>
            {t('common.lbl_ProductName')}
          </Col>
          {products &&
            products.map(
              (product) =>
                (viewType === 'Seller' ||
                  isSellerSelectedForGivenProduct(product)) && (
                  <Col className='text-start h5 ms-1 multiple-seller-card-product-max-width d-flex align-items-center'>
                    {viewType === 'Buyer' ? (
                      <BuyerFavoriteProductSwitch
                        isFavorite={product.isFavorite}
                        companyId={currentUser.companyId}
                        userId={currentUser.id}
                        productId={product.id}
                        sellerId={product.company.id}
                      />
                    ) : (
                      <SellerFavoriteProductSwitch
                        isFavorite={product.isFavorite}
                        companyId={currentUser.companyId}
                        userId={currentUser.id}
                        productId={product.id}
                      />
                    )}{' '}
                    <span className='ms-1'>{product.name}</span>
                  </Col>
                )
            )}
        </Row>
      </Card.Header>
    </>
  );
};

/** API for methods available only on Buyer level */

import { BuyerApiClient } from 'api/BuyerApiClient';
import { CancelToken } from 'axios';
import {
  ExternalSellerContactByIdModel,
  ExternalSellerContactModel,
  ExternalSellerContactUpsertModel,
} from 'models/buyer/contacts/ExternalSellerContactsModel';
import { SellerContactsModel } from 'models/buyer/contacts/SellerContactsModel';
import {
  DealCreateTagModel,
  DealGroupTagModel,
  DealTagModel,
  DealUpdateModel,
} from 'models/buyer/deals/DealsModels';
import {
  InquiriesModel,
  InquiryCreateModel,
  InquiryModel,
  InquiryRowUpdateModel,
  InquirySendModel,
  InquiryUpdateModel,
  InquiryUpdateTargetPriceModel,
} from 'models/buyer/documents/InquiryModels';
import { InvoiceModel } from 'models/buyer/documents/InvoiceModels';
import {
  OfferComparisonModel,
  OfferModel,
  OfferOnBehalfUpsertModel,
} from 'models/buyer/documents/OfferModels';
import {
  OrderCreateFromCherryPickOfferModel,
  OrderCreateFromOfferModel,
  OrderCreateFromProjectModel,
  OrderModel,
  OrderSendModel,
  OrderUpdateDraftModel,
} from 'models/buyer/documents/OrderModels';
import {
  BuyerGlobalNotificationsModel,
  BuyerGlobalNotificationsUpdateModel,
  BuyerPeronalNotificationsModel,
  BuyerPeronalNotificationsUpdateModel,
} from 'models/buyer/notifications/BuyerNotificationsModels';
import { BuyerFavoriteProductCreateModel } from 'models/buyer/product/BuyerFavoriteProductCreateModel';
import { BuyerGroupedProductListPagedResult } from 'models/buyer/product/BuyerGroupedProductListPagedResult';
import { BuyerProductClassInfoModel } from 'models/buyer/product/BuyerProductClassInfoModel';
import { BuyerProductListPagedResult } from 'models/buyer/product/BuyerProductListPagedResult';
import { ProductQuickSearchGroupedPagedModel } from 'models/buyer/product/ProductQuickSearchGroupedModel';
import { ProductQuickSearchModel } from 'models/buyer/product/ProductQuickSearchModel';
import { BuyerProjectRowInfoGroupedModel } from 'models/buyer/project/GroupedProjectModels';
import {
  AllProjectsNewModel,
  BuyerProjectRowInfoModel,
  CurrentProjects,
  FilterStateModel,
  ProjectImportProductsErrorModel,
  ProjectImportProductsModel,
  ProjectModel,
  ProjectNameUpdateModel,
  ProjectRowBulkUpdateModel,
  ProjectRowNewModel,
  ProjectRowUpdateModel,
  ProjectUpdateModel,
} from 'models/buyer/project/ProjectModels';
import {
  InquiryGroupAdditionalInquiriesResponseModel,
  ProjectInquiryAddRemoveSupplierModel,
  ProjectInquiryBulkUpdateRowModel,
  ProjectInquiryCreateResponseModel,
  ProjectInquiryCreateRowModel,
  ProjectInquiryModel,
  ProjectInquiryRowModel,
  ProjectInquirySendModel,
  ProjectInquiryUpdateModel,
  ProjectInquiryUpdateRowModel,
} from 'models/buyer/projectInquiry/ProjectInquiryModels';
import {
  ProjectOrderCreateOrderModel,
  ProjectOrderCreateResponseModel,
  ProjectOrderModel,
  ProjectOrderRowAddSupplierModel,
  ProjectOrderRowCreateModel,
  ProjectOrderRowDuplicateModel,
  ProjectOrderRowFreehandCreateModel,
  ProjectOrderRowGroupedModel,
  ProjectOrderRowUpdateModel,
  ProjectOrderUpdateModel,
} from 'models/buyer/projectOrder/ProjectOrderModels';
import {
  ExternalSellerImportPricelistResponseModel,
  ExternalSellerImportSuppliersResponseModel,
  ExternalSupplierCreateModel,
  ExternalSupplierUpdateModel,
} from 'models/buyer/suppliers/ExternalSupplierModels';
import { SellerCreditInfoModel } from 'models/buyer/suppliers/SellerCreditInfoModel';
import {
  SellerStocksModel,
  SellerUpdateIsFavoriteStockModel,
  SellerUpdateStocksModel,
} from 'models/buyer/suppliers/SellerStocksModel';
import { SellersCompaniesAndContactsModel } from 'models/buyer/suppliers/SellersCompanyAndContactsModel';
import { SellersCompaniesAndUsersModel } from 'models/buyer/suppliers/SellersCompanyAndUsersModel';
import { SellersUsersModel } from 'models/buyer/suppliers/SellersUsersModel';
import { SupplierAcceptInvitationModel } from 'models/buyer/suppliers/SupplierAcceptInvitationModel';
import { SupplierCompanyModel } from 'models/buyer/suppliers/SupplierCompanyModel';
import {
  AddressModel,
  AddressModelById,
} from 'models/shared/address/AddressModel';
import { AddressUpdateModel } from 'models/shared/address/AddressUpdateModel';
import { ProductDetailsModel } from 'models/shared/product/ProductDetailsModel';
import { ProductInstantModel } from 'models/shared/product/ProductModel';
import { FileBlob } from './ApiClient';

export const BuyerApi = {
  getAllDeliveryAddresses: (
    companyId: number,
    userId: number,
    page?: number | undefined,
    searchString?: string | undefined,
    rowsPerPage?: number | undefined,
    sortColumn?: string | undefined
  ): Promise<AddressModel> =>
    BuyerApiClient.get(
      `${companyId}/users/${userId}/DeliveryAddresses?SearchString=${
        searchString !== undefined ? searchString : ''
      }&PageSize=${rowsPerPage !== undefined ? rowsPerPage : 300}&PageNumber=${
        page !== undefined ? page : 1
      }&OrderBy=${sortColumn !== undefined ? sortColumn : 'name'}`
    ),

  getDeliveryAddressById: (
    companyId: number,
    userId: number,
    deliveryAddressId: number
  ): Promise<AddressModelById> =>
    BuyerApiClient.get(
      `${companyId}/users/${userId}/DeliveryAddresses/${deliveryAddressId}`
    ),

  createDeliveryAddresses: (
    companyId: number,
    userId: number,
    address: AddressUpdateModel
  ): Promise<string> =>
    BuyerApiClient.postCreate(
      `${companyId}/users/${userId}/DeliveryAddresses`,
      address
    ),

  updateDeliveryAddresses: (
    companyId: number,
    userId: number,
    address: AddressUpdateModel,
    addressId: number
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/users/${userId}/DeliveryAddresses/${addressId}`,
      address
    ),

  deleteDeliveryAddresses: (
    companyId: number,
    userId: number,
    addressId: number
  ): Promise<AddressModel> =>
    BuyerApiClient.delete(
      `${companyId}/users/${userId}/DeliveryAddresses/${addressId}`
    ),

  /* Suppliers */
  getSellersCompaniesAndUsers: (
    companyId: number,
    page?: number | undefined,
    searchString?: string | undefined,
    rowsPerPage?: number | undefined,
    sortColumn?: string | undefined
  ): Promise<SellersCompaniesAndUsersModel> =>
    BuyerApiClient.get(
      `${companyId}/Sellers/CompaniesAndUsers?SearchString=${
        searchString !== undefined ? searchString : ''
      }&PageSize=${rowsPerPage !== undefined ? rowsPerPage : 10}&PageNumber=${
        page !== undefined ? page : 1
      }&OrderBy=${sortColumn !== undefined ? sortColumn : 'name'}`
    ),

  getSellersCompaniesAndContacts: (
    companyId: number,
    page?: number | undefined,
    searchString?: string | undefined,
    rowsPerPage?: number | undefined,
    sortColumn?: string | undefined
  ): Promise<SellersCompaniesAndContactsModel> =>
    BuyerApiClient.get(
      `${companyId}/Sellers/CompaniesAndContacts?SearchString=${
        searchString !== undefined ? searchString : ''
      }&PageSize=${rowsPerPage ?? 100}&PageNumber=${
        page !== undefined ? page : 1
      }&OrderBy=${sortColumn !== undefined ? sortColumn : 'name'}`
    ),

  getSellersCompanies: (
    companyId: number,
    page?: number | undefined,
    searchString?: string | undefined,
    rowsPerPage?: number | undefined,
    sortColumn?: string | undefined
  ): Promise<SellersCompaniesAndUsersModel> =>
    BuyerApiClient.get(
      `${companyId}/Sellers/Companies?SearchString=${
        searchString !== undefined ? searchString : ''
      }
      &PageSize=${rowsPerPage !== undefined ? rowsPerPage : 100}
      &PageNumber=${page !== undefined ? page : 1}&OrderBy=${
        sortColumn !== undefined ? sortColumn : 'name'
      }`
    ),

  getSellersUsers: (
    companyId: number,
    page?: number | undefined,
    searchString?: string | undefined,
    rowsPerPage?: number | undefined,
    sortColumn?: string | undefined
  ): Promise<SellersUsersModel> =>
    BuyerApiClient.get(
      `${companyId}/Sellers/Users?SearchString=${
        searchString !== undefined ? searchString : ''
      }&PageSize=${rowsPerPage !== undefined ? rowsPerPage : 500}&PageNumber=${
        page !== undefined ? page : 1
      }&OrderBy=${sortColumn !== undefined ? sortColumn : 'name'}`
    ),

  getSupplierCompanyInfo: (
    companyId: number,
    supplierCompanyId: number
  ): Promise<SupplierCompanyModel> =>
    BuyerApiClient.get(`${companyId}/Sellers/Companies/${supplierCompanyId}`),

  getSupplierCreditInfo: (
    companyId: number,
    supplierCompanyId: number
  ): Promise<SellerCreditInfoModel> =>
    BuyerApiClient.get(
      `${companyId}/Sellers/${supplierCompanyId}/SellerCreditInfo`
    ),

  getSupplierPricelistExport: (
    companyId: number,
    supplierCompanyId: number,
    localizationId: number
  ): Promise<FileBlob> =>
    BuyerApiClient.getFile(
      `${companyId}/Sellers/${supplierCompanyId}/SellerPriceListExport?localizationId=${localizationId}`
    ),

  getSellerStocks: (
    companyId: number,
    supplierCompanyId: number
  ): Promise<SellerStocksModel[]> =>
    BuyerApiClient.get(
      `${companyId}/Sellers/${supplierCompanyId}/SellerStocks`
    ),

  updateSellerStocks: (
    companyId: number,
    supplierCompanyId: number,
    stock: SellerUpdateStocksModel,
    stockId: number
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/Sellers/${supplierCompanyId}/SellerStocks/${stockId}`,
      stock
    ),

  updateSellerIsFavoriteStock: (
    companyId: number,
    supplierCompanyId: number,
    stock: SellerUpdateIsFavoriteStockModel,
    stockId: number
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/Sellers/${supplierCompanyId}/SellerStocks/ManageFavoriteStock/${stockId}`,
      stock
    ),

  acceptSupplierInvitation: (
    companyId: number,
    sellerCompanyId: number,
    invitation: SupplierAcceptInvitationModel
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/SellerInvitations/${sellerCompanyId}`,
      invitation
    ),

  getSellerContacts: (
    companyId: number,
    sellerCompanyId: number
  ): Promise<SellerContactsModel[]> =>
    BuyerApiClient.get(
      `${companyId}/Sellers/${sellerCompanyId}/SellerContacts`
    ),

  // Products
  getAllProductClasses: (
    companyId: number,
    sellerCompanyId: number | null,
    level: number | null,
    classId: number | null,
    localizationId: number | null
  ): Promise<BuyerProductClassInfoModel> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProductClasses/GetMP?sellerCompanyId=${sellerCompanyId}&level=${
        level || ''
      }&classId=${classId || ''}&localizationId=${localizationId}`
    ),

  getAllSubSellerProductClasses: (
    companyId: number,
    sellerCompanyId: number | null,
    level: number | null,
    classId: number | null,
    localizationId: number | null
  ): Promise<BuyerProductClassInfoModel> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProductClasses/GetSubSellerClasses?sellerCompanyId=${sellerCompanyId}&level=${
        level || ''
      }&classId=${classId || ''}&localizationId=${localizationId}`
    ),

  getProducts: (
    companyId: number,
    sellerCompanyIds: number[] | null,
    productClassId: string,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null,
    isSimpleSearch: boolean
  ): Promise<BuyerProductListPagedResult> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts?${
        sellerCompanyIds &&
        sellerCompanyIds.map((id) => 'SellerCompanyIds=' + id + '&').join('')
      }productClassId=${productClassId || ''}&searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}&isSimpleSearch=${isSimpleSearch}`.replaceAll(
        ',',
        ''
      )
    ),

  getProductsGrouped: (
    companyId: number,
    sellerCompanyIds: number[] | null,
    productClassId: string,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null,
    isSimpleSearch: boolean,
    productClassSellerId: number,
    manufacturers: string[]
  ): Promise<BuyerGroupedProductListPagedResult> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts/GetMP?${
        manufacturers
          ? manufacturers
              .map((name) => 'ProductManufacturers=' + name + '&')
              .join('')
          : ''
      }${
        sellerCompanyIds &&
        sellerCompanyIds.map((id) => 'SellerCompanyIds=' + id + '&').join('')
      }productClassId=${
        productClassId || ''
      }&productClassSellerId=${productClassSellerId}&searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}&isSimpleSearch=${isSimpleSearch}`.replaceAll(
        ',',
        ''
      )
    ),

  getQuickProducts: (
    companyId: number,
    searchString: string,
    localizationId: number | null
  ): Promise<ProductQuickSearchModel[]> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts/QuickSearch?searchString=${
        searchString || ''
      }&localizationId=${localizationId}`
    ),

  getQuickProductsGrouped: (
    companyId: number,
    searchString: string,
    localizationId: number | null,
    manufacturers?: string[]
  ): Promise<ProductQuickSearchGroupedPagedModel> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts/QuickSearchPaginated?${
        manufacturers
          ? manufacturers
              .map((name) => 'ProductManufacturers=' + name + '&')
              .join('')
          : ''
      }pageNumber=1&pageSize=20&searchString=${
        searchString || ''
      }&localizationId=${localizationId}`
    ),

  getQuickProductsGroupedPaginated: (
    companyId: number,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    localizationId: number | null,
    manufacturers?: string[]
  ): Promise<ProductQuickSearchGroupedPagedModel> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts/QuickSearchPaginated?${
        manufacturers
          ? manufacturers
              .map((name) => 'ProductManufacturers=' + name + '&')
              .join('')
          : ''
      }searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=20&localizationId=${localizationId}`
    ),

  findProducts: (
    companyId: number,
    sellerCompanyIds: number[] | null,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null,
    isSimpleSearch: boolean
  ): Promise<BuyerProductListPagedResult> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts?${
        sellerCompanyIds &&
        sellerCompanyIds.map((id) => 'SellerIds=' + id + '&').join('')
      }searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}&isSimpleSearch=${isSimpleSearch}`
    ),

  findProductsGrouped: (
    companyId: number,
    sellerCompanyIds: number[] | null,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null,
    isSimpleSearch: boolean,
    manufacturers?: string[] | null,
    sourceToken?: CancelToken
  ): Promise<BuyerGroupedProductListPagedResult> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts/GetMP?${
        manufacturers
          ? manufacturers
              .map((name) => 'ProductManufacturers=' + name + '&')
              .join('')
          : ''
      }${
        sellerCompanyIds &&
        sellerCompanyIds.map((id) => 'SellerCompanyIds=' + id + '&').join('')
      }searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}&isSimpleSearch=${isSimpleSearch}`,
      sourceToken
    ),

  findProductsSimple: (
    companyId: number,
    sellerCompanyIds: number[] | null,

    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null,
    isSimpleSearch: boolean,
    manufacturers?: string[] | null,
    productClassSellerId?: number | null,
    classId?: string | null
  ): Promise<BuyerGroupedProductListPagedResult> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts/GetSimple?${
        manufacturers
          ? manufacturers
              .map((name) => 'ProductManufacturers=' + name + '&')
              .join('')
          : ''
      }${
        sellerCompanyIds &&
        sellerCompanyIds.map((id) => 'SellerCompanyIds=' + id + '&').join('')
      }searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}&isSimpleSearch=${isSimpleSearch}&ProductClassId=${
        classId ?? ''
      }&ProductClassSellerId=${productClassSellerId ?? ''}`
    ),

  findProductsInstant: (
    companyId: number,
    sellerCompanyIds: number[] | null,
    searchString: string,
    localizationId: number | null
  ): Promise<ProductInstantModel[]> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts/GetInstant?${
        sellerCompanyIds &&
        sellerCompanyIds.map((id) => 'SellerCompanyIds=' + id + '&').join('')
      }searchString=${
        searchString || ''
      }&pageSize=5&localizationId=${localizationId}`
    ),

  findProductsGroupedByCode: (
    companyId: number,
    sellerCompanyIds: number[] | null,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null,
    isSimpleSearch: boolean,
    manufacturers?: string[]
  ): Promise<BuyerGroupedProductListPagedResult> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts/GetByCodeMP?${
        manufacturers &&
        manufacturers
          .map((name) => 'ProductManufacturers=' + name + '&')
          .join('')
      }${
        sellerCompanyIds &&
        sellerCompanyIds.map((id) => 'SellerCompanyIds=' + id + '&').join('')
      }searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}&isSimpleSearch=${isSimpleSearch}`
    ),

  // TODO: Dennis, remove this and uncomment the one above
  /*   findProducts: (
    companyId: number,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null
  ): Promise<ProductModelPagedResult> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts?searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}`
    ), */

  getProduct: (
    companyId: number,
    productId: number,
    localizationId: number
  ): Promise<ProductDetailsModel> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts/${productId}?localizationId=${localizationId}`
    ),

  getProductsByCode: (
    companyId: number,
    productCode: string,
    localizationId: number
  ): Promise<ProductDetailsModel[]> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProducts/Code?productCode=${productCode}&localizationId=${localizationId}`
    ),

  /* Projects */
  getAllProjects: (
    companyId: number,
    sellerCompanyIds: number[] | null,
    filterState?: FilterStateModel
  ): Promise<AllProjectsNewModel> =>
    filterState !== undefined
      ? BuyerApiClient.get(
          `${companyId}/BuyerProjects/GetAll?${
            sellerCompanyIds &&
            sellerCompanyIds
              .map((id) => 'SellerCompanyIds=' + id + '&')
              .join('')
          }ownerId=${
            filterState.ownerId ? filterState.ownerId : 'null'
          }&dealStatus=${
            filterState.dealStatus !== undefined &&
            filterState.dealStatus !== null &&
            filterState.dealStatus !== 'all'
              ? filterState.dealStatus
              : ''
          }&inquiryStatus=${
            filterState.inquiryStatus !== undefined &&
            filterState.inquiryStatus !== null &&
            filterState.inquiryStatus !== 'all'
              ? filterState.inquiryStatus
              : ''
          }&offerStatus=${
            filterState.offerStatus !== undefined &&
            filterState.offerStatus !== null &&
            filterState.offerStatus !== 'all'
              ? filterState.offerStatus
              : ''
          }&orderStatus=${
            filterState.orderStatus !== undefined &&
            filterState.orderStatus !== null &&
            filterState.orderStatus !== 'all'
              ? filterState.orderStatus
              : ''
          }&invoiceStatus=${
            filterState.invoiceStatus !== undefined &&
            filterState.invoiceStatus !== null &&
            filterState.invoiceStatus !== 'all'
              ? filterState.invoiceStatus
              : ''
          }&searchString=${
            filterState.searchString !== undefined &&
            filterState.searchString !== null &&
            filterState.searchString !== 'null' &&
            filterState.searchString !== 'undefined'
              ? filterState.searchString
              : ''
          }${
            filterState.tagIds !== undefined &&
            filterState.tagIds.length > 0 &&
            !isNaN(filterState.tagIds[0])
              ? filterState.tagIds.map((id) => '&TagIds=' + id).join('')
              : ''
          }&PageSize=30&PageNumber=${filterState.pageNumber}`
        )
      : BuyerApiClient.get(`${companyId}/BuyerProjects`),

  getProject: (companyId: number, projectId: number): Promise<ProjectModel> =>
    BuyerApiClient.get(`${companyId}/BuyerProjects/${projectId}`),

  createProject: (
    companyId: number,
    project: { name: string; sellerCompanyId: number }
  ): Promise<number> =>
    BuyerApiClient.post(`${companyId}/BuyerProjects`, project),

  updateProject: (
    companyId: number,
    projectId: number,
    project: ProjectUpdateModel
  ): Promise<void> =>
    BuyerApiClient.put(`${companyId}/BuyerProjects/${projectId}`, project),

  updateProjectName: (
    companyId: number,
    projectId: number,
    project: ProjectNameUpdateModel
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/BuyerProjects/${projectId}/UpdateName`,
      project
    ),

  deleteProject: (companyId: number, projectId: number): Promise<void> =>
    BuyerApiClient.delete(`${companyId}/BuyerProjects/${projectId}`),

  getProjectRows: (
    companyId: number,
    projectId: number
  ): Promise<BuyerProjectRowInfoModel[]> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProjects/${projectId}/BuyerProjectRows`
    ),

  getProjectRowsGrouped: (
    companyId: number,
    projectId: number
  ): Promise<BuyerProjectRowInfoGroupedModel[]> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProjects/${projectId}/BuyerProjectRows/GetProjectRowsGrouped`
    ),

  createProjectRows: (
    companyId: number,
    projectId: number,
    projectRow: ProjectRowNewModel[]
  ): Promise<void> =>
    BuyerApiClient.post(
      `${companyId}/BuyerProjects/${projectId}/BuyerProjectRows`,
      projectRow
    ),

  updateProjectRows: (
    companyId: number,
    projectId: number,
    projectRows: ProjectRowUpdateModel[]
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/BuyerProjects/${projectId}/BuyerProjectRows`,
      projectRows
    ),

  bulkUpdateProjectRow: (
    companyId: number,
    projectId: number,
    projectRows: ProjectRowBulkUpdateModel[]
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/BuyerProjects/${projectId}/BuyerProjectRows/bulkUpdate`,
      projectRows
    ),

  deleteProjectRows: (
    companyId: number,
    projectId: number,
    projectRowIds: number[]
  ): Promise<void> =>
    BuyerApiClient.delete(
      `${companyId}/BuyerProjects/${projectId}/BuyerProjectRows?${projectRowIds
        .map((x) => `projectRowIds=${x}`)
        .join('&')}`
    ),

  importProductFromText: (
    companyId: number,
    projectId: number,
    importScope: ProjectImportProductsModel
  ): Promise<ProjectImportProductsErrorModel[]> =>
    BuyerApiClient.post(
      `${companyId}/BuyerProjects/${projectId}/BuyerProjectRows/Import`,
      importScope
    ),

  getRecentCurrentProjects: (
    companyId: number,
    userId: number
  ): Promise<CurrentProjects[]> =>
    BuyerApiClient.get(`${companyId}/BuyerUsers/${userId}/CurrentProjects`),

  projectsQuickSearch: (
    companyId: number,
    searchString: string
  ): Promise<CurrentProjects[]> =>
    BuyerApiClient.get(
      `${companyId}/BuyerProjects/QuickSearch?SearchString=${searchString}`
    ),

  /* Inquiries */
  getInquiry: (
    companyId: number,
    inquiryId: number,
    localizationId: number
  ): Promise<InquiryModel> =>
    BuyerApiClient.get(
      `${companyId}/BuyerInquiries/${inquiryId}?localizationId=${localizationId}`
    ),

  getInquiries: (companyId: number): Promise<InquiriesModel[]> =>
    BuyerApiClient.get(`${companyId}/BuyerInquiries`),

  createInquiry: (
    companyId: number,
    inquiry: InquiryCreateModel
  ): Promise<number> =>
    BuyerApiClient.post(`${companyId}/BuyerInquiries/FromProject`, inquiry),

  updateInquiry: (
    companyId: number,
    inquiryId: number,
    inquiry: InquiryUpdateModel
  ): Promise<void> =>
    BuyerApiClient.put(`${companyId}/BuyerInquiries/${inquiryId}`, inquiry),

  updateInquiryRow: (
    companyId: number,
    inquiryRowId: number,
    inquiryRow: InquiryRowUpdateModel,
    inquiryId: number
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/BuyerInquiries/${inquiryId}/BuyerInquiryRows/${inquiryRowId}`,
      inquiryRow
    ),

  sendInquiry: (companyId: number, inquiry: InquirySendModel): Promise<void> =>
    BuyerApiClient.post(`${companyId}/BuyerInquiries/Send`, inquiry),

  deleteInquiry: (companyId: number, inquiryId: number): Promise<void> =>
    BuyerApiClient.delete(`${companyId}/BuyerInquiries/${inquiryId}`),

  /* Offers */
  getOffer: (
    companyId: number,
    offerId: number,
    localizationId: number,
    sortColumn?: string,
    desc?: boolean
  ): Promise<OfferModel> => {
    let sortString = '';

    if (sortColumn != null && sortColumn != undefined) {
      sortString = `&sortColumn=${sortColumn}`;
      if (desc != null && desc != undefined) {
        sortString += `&desc=${desc}`;
      }
    }

    return BuyerApiClient.get(
      `${companyId}/BuyerOffers/${offerId}?localizationId=${localizationId}${sortString}`
    );
  },

  getOfferComparison: (
    companyId: number,
    dealGroupId: number
  ): Promise<OfferComparisonModel> => {
    return BuyerApiClient.get(
      `${companyId}/BuyerOffers/dealGroup/${dealGroupId}/compare`
    );
  },

  createOfferOnBehalf: (
    companyId: number,
    dealGroupId: number,
    offer: OfferOnBehalfUpsertModel
  ): Promise<number> =>
    BuyerApiClient.post(
      `${companyId}/BuyerOffers/dealGroup/${dealGroupId}/InsertExternal`,
      offer
    ),

  updateOfferOnBehalf: (
    companyId: number,
    offerId: number,
    offer: OfferOnBehalfUpsertModel
  ): Promise<number> =>
    BuyerApiClient.put(
      `${companyId}/BuyerOffers/${offerId}/UpdateExternal`,
      offer
    ),

  deleteOfferOnBehalf: (companyId: number, offerId: number): Promise<number> =>
    BuyerApiClient.delete(`${companyId}/BuyerOffers/${offerId}`),

  /* Orders */
  getOrder: (
    companyId: number,
    orderId: number,
    localizationId: number
  ): Promise<OrderModel> =>
    BuyerApiClient.get(
      `${companyId}/BuyerOrders/${orderId}?localizationId=${localizationId}`
    ),

  createOrderFromProject: (
    companyId: number,
    projectData: OrderCreateFromProjectModel
  ): Promise<number> =>
    BuyerApiClient.post(`${companyId}/BuyerOrders/FromProject`, projectData),

  createOrderFromCherryPickOffer: (
    companyId: number,
    offerData: OrderCreateFromCherryPickOfferModel
  ): Promise<number> =>
    BuyerApiClient.post(
      `${companyId}/BuyerOrders/CherryPickFromOffer`,
      offerData
    ),

  createOrderFromOffer: (
    companyId: number,
    offerData: OrderCreateFromOfferModel
  ): Promise<number> =>
    BuyerApiClient.post(`${companyId}/BuyerOrders/FromOffer`, offerData),

  updateOrder: (
    companyId: number,
    orderId: number,
    projectData: OrderUpdateDraftModel
  ): Promise<void> =>
    BuyerApiClient.put(`${companyId}/BuyerOrders/${orderId}`, projectData),

  sendOrder: (companyId: number, order: OrderSendModel): Promise<void> =>
    BuyerApiClient.post(`${companyId}/BuyerOrders/Send`, order),

  /* Invoices */
  getInvoice: (
    companyId: number,
    invoiceId: number,
    localizationId: number
  ): Promise<InvoiceModel> =>
    BuyerApiClient.get(
      `${companyId}/BuyerInvoices/${invoiceId}?localizationId=${localizationId}`
    ),

  /* Deals */
  updateDeal: (
    companyId: number,
    projectId: number,
    dealId: number,
    deal: DealUpdateModel
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/BuyerProjects/${projectId}/BuyerDeals/${dealId}`,
      deal
    ),

  bulkUpdateDeal: (
    companyId: number,
    projectId: number,
    deal: DealUpdateModel
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/BuyerProjects/${projectId}/BuyerDeals`,
      deal
    ),

  /* Favorite products */
  createFavoriteProduct: (
    companyId: number,
    userId: number,
    favoriteProduct: BuyerFavoriteProductCreateModel
  ): Promise<number> =>
    BuyerApiClient.post(
      `${companyId}/users/${userId}/BuyerFavoriteProducts`,
      favoriteProduct
    ),

  deleteFavoriteProduct: (
    companyId: number,
    userId: number,
    productId: number
  ): Promise<void> =>
    BuyerApiClient.delete(
      `${companyId}/users/${userId}/BuyerFavoriteProducts/${productId}`
    ),

  getFavoriteProducts: (
    companyId: number,
    userId: number,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null
  ): Promise<BuyerProductListPagedResult> =>
    BuyerApiClient.get(
      `${companyId}/users/${userId}/BuyerFavoriteProducts?&searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}`
    ),

  getFavoriteProductsGrouped: (
    companyId: number,
    userId: number,
    searchString: string,
    pageNumber: number | null,
    orderBy: string,
    pageSize: number | null,
    localizationId: number | null
  ): Promise<BuyerGroupedProductListPagedResult> =>
    BuyerApiClient.get(
      `${companyId}/users/${userId}/BuyerFavoriteProducts/Grouped?&searchString=${
        searchString || ''
      }&pageNumber=${pageNumber}&orderBy=${orderBy}&pageSize=${pageSize}&localizationId=${localizationId}`
    ),

  /* Tags */
  createTag: (
    companyId: number,
    dealId: number,
    tag: DealCreateTagModel
  ): Promise<number> =>
    BuyerApiClient.post(`${companyId}/BuyerTags/${dealId}`, tag),

  getLastTags: (
    companyId: number,
    searchString?: string
  ): Promise<DealTagModel[]> =>
    BuyerApiClient.get(
      `${companyId}/BuyerTags?SearchString=${searchString || ''}`
    ),

  updateTag: (
    companyId: number,
    tagId: number,
    dealId: number
  ): Promise<void> =>
    BuyerApiClient.put(`${companyId}/BuyerTags/${dealId}/${tagId}`, {}),

  deleteTag: (
    companyId: number,
    tagId: number,
    dealId: number
  ): Promise<void> =>
    BuyerApiClient.delete(`${companyId}/BuyerTags/${dealId}/${tagId}`),

  tags: {
    createDealGroupTag: (
      companyId: number,
      dealGroupId: number,
      tag: DealGroupTagModel
    ): Promise<number> =>
      BuyerApiClient.post(
        `${companyId}/BuyerTags/DealGroup/${dealGroupId}`,
        tag
      ),

    updateDealGroupTag: (
      companyId: number,
      dealGroupId: number,
      tagId: number,
      type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice'
    ): Promise<number> =>
      BuyerApiClient.put(
        `${companyId}/BuyerTags/${tagId}/DealGroup/${dealGroupId}/${type}`,
        {}
      ),

    deleteDealGroupTag: (
      companyId: number,
      tagId: number,
      dealGroupId: number,
      type: 'Inquiry' | 'Order' | 'Offer' | 'Invoice'
    ): Promise<number> =>
      BuyerApiClient.delete(
        `${companyId}/BuyerTags/${tagId}/DealGroup/${dealGroupId}/${type}`
      ),
  },

  /* Notifications */
  getBuyerGlobalNotifications: (
    companyId: number
  ): Promise<BuyerGlobalNotificationsModel> =>
    BuyerApiClient.get(`${companyId}/BuyerNotificationSettings`),

  updateBuyerGlobalNotifications: (
    companyId: number,
    notificationSettings: BuyerGlobalNotificationsUpdateModel
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/BuyerNotificationSettings`,
      notificationSettings
    ),

  getBuyerPersonalNotifications: (
    companyId: number,
    userId: number
  ): Promise<BuyerPeronalNotificationsModel> =>
    BuyerApiClient.get(
      `${companyId}/users/${userId}/BuyerUserNotificationSettings`
    ),

  updateBuyerPersonalNotifications: (
    companyId: number,
    userId: number,
    notificationSettings: BuyerPeronalNotificationsUpdateModel
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/users/${userId}/BuyerUserNotificationSettings`,
      notificationSettings
    ),

  /* Favorite seller */
  updateFavoriteSeller: (
    companyId: number,
    userId: number,
    favoriteSellerId: { id: number }
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/BuyerUsers/${userId}/BuyerFavoriteSeller`,
      favoriteSellerId
    ),

  /* External seller */
  createExternalSeller: (
    companyId: number,
    externalSeller: ExternalSupplierCreateModel
  ): Promise<number> =>
    BuyerApiClient.postCreate(`${companyId}/ExternalSellers`, externalSeller),

  updateExternalSeller: (
    companyId: number,
    externalSellerId: number,
    externalSeller: ExternalSupplierUpdateModel
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/ExternalSellers/${externalSellerId}`,
      externalSeller
    ),

  getExternalSellerLogo: (
    companyId: number,
    externalSellerId: number
  ): Promise<string> =>
    BuyerApiClient.get(
      `${companyId}/ExternalSellers/${externalSellerId}/ExternalSellerLogo`
    ),

  uploadExternalSellerLogo: (
    companyId: number,
    externalSellerId: number,
    file: FormData
  ): Promise<string> =>
    BuyerApiClient.postCreate(
      `${companyId}/ExternalSellers/${externalSellerId}/ExternalSellerLogo`,
      file
    ),

  deleteExternalSellerLogo: (
    companyId: number,
    externalSellerId: number
  ): Promise<void> =>
    BuyerApiClient.delete(
      `${companyId}/ExternalSellers/${externalSellerId}/ExternalSellerLogo`
    ),

  restoreExternalSeller: (companyId: number, id: number): Promise<void> =>
    BuyerApiClient.put(`${companyId}/ExternalSellers/${id}/Restore`, {}),

  checkIsExternalSellerArchived: (
    companyId: number,
    name: string
  ): Promise<boolean> =>
    BuyerApiClient.get(
      `${companyId}/ExternalSellers/IsNotDeleted?name=${name}`
    ),

  softDeleteExternalSeller: (companyId: number, id: number): Promise<void> =>
    BuyerApiClient.delete(`${companyId}/ExternalSellers/${id}`),

  importPricelistExternalSeller: (
    companyId: number,
    externalSellerId: number,
    body: FormData
  ): Promise<ExternalSellerImportPricelistResponseModel> =>
    BuyerApiClient.post(
      `${companyId}/ExternalSellers/${externalSellerId}/ExternalSellerProducts/Import`,
      body
    ),

  importSuppliersExternalSeller: (
    companyId: number,
    body: FormData
  ): Promise<ExternalSellerImportSuppliersResponseModel> =>
    BuyerApiClient.post(
      `${companyId}/ExternalSellers/ImportExternalSellers`,
      body
    ),

  deletePricelistExternalSeller: (
    companyId: number,
    externalSellerId: number
  ): Promise<ExternalSellerImportPricelistResponseModel> =>
    BuyerApiClient.delete(
      `${companyId}/ExternalSellers/${externalSellerId}/ExternalSellerProducts`
    ),

  /* External seller contacts */
  getExternalSellerContacts: (
    companyId: number,
    externalSellerId: number
  ): Promise<ExternalSellerContactModel[]> =>
    BuyerApiClient.get(
      `${companyId}/ExternalSellers/${externalSellerId}/ExternalSellerContacts`
    ),

  getExternalSellerContactById: (
    companyId: number,
    externalSellerId: number,
    id: number
  ): Promise<ExternalSellerContactByIdModel> =>
    BuyerApiClient.get(
      `${companyId}/ExternalSellers/${externalSellerId}/ExternalSellerContacts/${id}`
    ),

  deleteExternalSellerContact: (
    companyId: number,
    externalSellerId: number,
    id: number
  ): Promise<void> =>
    BuyerApiClient.delete(
      `${companyId}/ExternalSellers/${externalSellerId}/ExternalSellerContacts/${id}`
    ),

  createExternalSellerContacts: (
    companyId: number,
    externalSellerId: number,
    contact: ExternalSellerContactUpsertModel
  ): Promise<string> =>
    BuyerApiClient.postCreate(
      `${companyId}/ExternalSellers/${externalSellerId}/ExternalSellerContacts`,
      contact
    ),

  updateExternalSellerContacts: (
    companyId: number,
    externalSellerId: number,
    contactId: number,
    contact: ExternalSellerContactUpsertModel
  ): Promise<void> =>
    BuyerApiClient.put(
      `${companyId}/ExternalSellers/${externalSellerId}/ExternalSellerContacts/${contactId}`,
      contact
    ),

  /* Project Inquiry */
  projectInquiry: {
    getProjectInquiry: (
      companyId: number,
      projectId: number,
      projectInquiryId: number
    ): Promise<ProjectInquiryModel> =>
      BuyerApiClient.get(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}`
      ),

    createProjectInquiry: (
      companyId: number,
      projectId: number
    ): Promise<number> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects`,
        {}
      ),

    updateProjectInquiry: (
      companyId: number,
      projectId: number,
      projectInquiryId: number,
      project: ProjectInquiryUpdateModel
    ): Promise<void> =>
      BuyerApiClient.put(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}`,
        project
      ),

    deleteProjectInquiry: (
      companyId: number,
      projectInquiryId: number,
      projectId: number
    ): Promise<void> =>
      BuyerApiClient.delete(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}`
      ),

    getProjectInquiryRows: (
      companyId: number,
      projectInquiryId: number,
      projectId: number
    ): Promise<ProjectInquiryRowModel[]> =>
      BuyerApiClient.get(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}/BuyerInquiryProjectRows`
      ),

    createProjectInquiryRows: (
      companyId: number,
      projectId: number,
      projectInquiryId: number,
      projectRow: ProjectInquiryCreateRowModel[]
    ): Promise<void> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}/BuyerInquiryProjectRows`,
        projectRow
      ),

    updateProjectInquiryRow: (
      companyId: number,
      projectId: number,
      projectInquiryId: number,
      projectRows: ProjectInquiryUpdateRowModel,
      rowId: number
    ): Promise<void> =>
      BuyerApiClient.put(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}/BuyerInquiryProjectRows/${rowId}`,
        projectRows
      ),

    updateBulkProjectInquiryRows: (
      companyId: number,
      projectId: number,
      projectInquiryId: number,
      projectRows: ProjectInquiryBulkUpdateRowModel
    ): Promise<void> =>
      BuyerApiClient.put(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}/BuyerInquiryProjectRows/Replacement`,
        projectRows
      ),

    deleteProjectInquiryRows: (
      companyId: number,
      projectId: number,
      projectInquiryId: number,
      projectRowId: number
    ): Promise<void> =>
      BuyerApiClient.delete(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}/BuyerInquiryProjectRows/${projectRowId}`
      ),

    addProjectInquirySupplier: (
      companyId: number,
      projectId: number,
      projectInquiryId: number,
      supplier: ProjectInquiryAddRemoveSupplierModel
    ): Promise<void> =>
      BuyerApiClient.put(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}/UpsertSupplier`,
        supplier
      ),

    importProductFromText: (
      companyId: number,
      projectId: number,
      projectInquiryId: number,
      importScope: ProjectImportProductsModel
    ): Promise<ProjectImportProductsErrorModel[]> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}/BuyerInquiryProjectRows/Import`,
        importScope
      ),

    importProductFromExcel: (
      companyId: number,
      projectId: number,
      projectInquiryId: number,
      body: FormData
    ): Promise<ProjectImportProductsErrorModel[]> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}/BuyerInquiryProjectRows/ExcelImport`,
        body
      ),

    removeProjectInquirySupplier: (
      companyId: number,
      projectId: number,
      projectInquiryId: number,
      supplier: ProjectInquiryAddRemoveSupplierModel
    ): Promise<void> =>
      BuyerApiClient.delete(
        `${companyId}/BuyerProjects/${projectId}/BuyerInquiryProjects/${projectInquiryId}/RemoveSupplier/${supplier.sellerCompanyId}`
      ),

    sendProjectInquiry: (
      companyId: number,
      projectInquiry: ProjectInquirySendModel
    ): Promise<void> =>
      BuyerApiClient.post(
        `${companyId}/BuyerInquiries/FromInquiryProject`,
        projectInquiry
      ),
  },

  /* Project Order */
  projectOrder: {
    getProjectOrder: (
      companyId: number,
      projectOrderId: number,
      projectId: number
    ): Promise<ProjectOrderModel> =>
      BuyerApiClient.get(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}`
      ),

    createProjectOrder: (
      companyId: number,
      projectId: number
    ): Promise<number> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects`,
        {}
      ),

    updateProjectOrder: (
      companyId: number,
      projectId: number,
      projectOrderId: number,
      project: ProjectOrderUpdateModel
    ): Promise<void> =>
      BuyerApiClient.put(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}`,
        project
      ),

    deleteProjectOrder: (
      companyId: number,
      projectOrderId: number,
      projectId: number
    ): Promise<void> =>
      BuyerApiClient.delete(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}`
      ),

    getProjectOrderRows: (
      companyId: number,
      projectOrderId: number,
      projectId: number
    ): Promise<ProjectOrderRowGroupedModel[]> =>
      BuyerApiClient.get(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/BuyerOrderProjectRows/GroupedByCode`
      ),

    createProjectOrderRows: (
      companyId: number,
      projectId: number,
      projectOrderId: number,
      projectRow: ProjectOrderRowCreateModel[]
    ): Promise<void> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/BuyerOrderProjectRows`,
        projectRow
      ),

    bulkUpdateProjectOrderRows: (
      companyId: number,
      projectId: number,
      projectOrderId: number,
      projectRows: ProjectRowBulkUpdateModel[]
    ): Promise<void> =>
      BuyerApiClient.put(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/BuyerOrderProjectRows/bulkUpdate`,
        projectRows
      ),

    createProjectOrderFreehandRows: (
      companyId: number,
      projectId: number,
      projectOrderId: number,
      projectRow: ProjectOrderRowFreehandCreateModel
    ): Promise<void> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/BuyerOrderProjectRows/Freehand`,
        projectRow
      ),

    updateProjectOrderRows: (
      companyId: number,
      projectId: number,
      projectOrderId: number,
      projectRows: ProjectOrderRowUpdateModel[]
    ): Promise<void> =>
      BuyerApiClient.put(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/BuyerOrderProjectRows`,
        projectRows
      ),

    importProductFromText: (
      companyId: number,
      projectId: number,
      projectOrderId: number,
      importScope: ProjectImportProductsModel
    ): Promise<ProjectImportProductsErrorModel[]> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/BuyerOrderProjectRows/Import`,
        importScope
      ),

    importProductFromExcel: (
      companyId: number,
      projectId: number,
      projectOrderId: number,
      body: FormData
    ): Promise<ProjectImportProductsErrorModel[]> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/BuyerOrderProjectRows/ExcelImport`,
        body
      ),

    deleteProjectOrderRows: (
      companyId: number,
      projectId: number,
      projectOrderId: number,
      projectRowIds: number[]
    ): Promise<void> =>
      BuyerApiClient.delete(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/BuyerOrderProjectRows?${projectRowIds
          .map((x) => `projectRowIds=${x}`)
          .join('&')}`
      ),

    addSupplierProjectOrderRows: (
      companyId: number,
      projectId: number,
      projectOrderId: number,
      supplier: ProjectOrderRowAddSupplierModel
    ): Promise<void> =>
      BuyerApiClient.put(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/BuyerOrderProjectRows/AddSupplier`,
        supplier
      ),

    duplicateProjectOrderRows: (
      companyId: number,
      projectId: number,
      projectOrderId: number,
      supplier: ProjectOrderRowDuplicateModel
    ): Promise<void> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/BuyerOrderProjectRows/Duplicate`,
        supplier
      ),

    closeProjectOrder: (
      companyId: number,
      projectId: number,
      projectOrderId: number
    ): Promise<void> =>
      BuyerApiClient.post(
        `${companyId}/BuyerProjects/${projectId}/BuyerOrderProjects/${projectOrderId}/Close`,
        {}
      ),

    createOrderFromProjectOrder: (
      companyId: number,
      order: ProjectOrderCreateOrderModel
    ): Promise<number> =>
      BuyerApiClient.post(`${companyId}/BuyerOrders/FromOrderProject`, order),
  },

  projects: {
    getProjectsNew: (
      companyId: number,
      sellerCompanyIds: number[] | null,
      filterState?: FilterStateModel
    ): Promise<AllProjectsNewModel> =>
      filterState !== undefined
        ? BuyerApiClient.get(
            `${companyId}/BuyerProjects/GetAll?${
              sellerCompanyIds &&
              sellerCompanyIds
                .map((id) => 'SellerCompanyIds=' + id + '&')
                .join('')
            }ownerId=${
              filterState.ownerId === 'all' ? '' : filterState.ownerId
            }&dealStatus=${
              filterState.dealStatus !== undefined &&
              filterState.dealStatus !== null &&
              filterState.dealStatus !== 'all'
                ? filterState.dealStatus
                : ''
            }&inquiryStatus=${
              filterState.inquiryStatus !== undefined &&
              filterState.inquiryStatus !== null &&
              filterState.inquiryStatus !== 'all'
                ? filterState.inquiryStatus
                : ''
            }&offerStatus=${
              filterState.offerStatus !== undefined &&
              filterState.offerStatus !== null &&
              filterState.offerStatus !== 'all'
                ? filterState.offerStatus
                : ''
            }&orderStatus=${
              filterState.orderStatus !== undefined &&
              filterState.orderStatus !== null &&
              filterState.orderStatus !== 'all'
                ? filterState.orderStatus
                : ''
            }&invoiceStatus=${
              filterState.invoiceStatus !== undefined &&
              filterState.invoiceStatus !== null &&
              filterState.invoiceStatus !== 'all'
                ? filterState.invoiceStatus
                : ''
            }&searchString=${
              filterState.searchString !== undefined &&
              filterState.searchString !== null &&
              filterState.searchString !== 'null' &&
              filterState.searchString !== 'undefined'
                ? filterState.searchString
                : ''
            }${
              filterState.tagIds !== undefined &&
              filterState.tagIds.length > 0 &&
              !isNaN(filterState.tagIds[0])
                ? filterState.tagIds.map((id) => '&TagIds=' + id).join('')
                : ''
            }&PageSize=30&PageNumber=${filterState.pageNumber}`
          )
        : BuyerApiClient.get(`${companyId}/BuyerProjects/GetAll`),

    createProjectAndProjectInquiry: (
      companyId: number
    ): Promise<ProjectInquiryCreateResponseModel> =>
      BuyerApiClient.post(`${companyId}/BuyerProjects/PostInquiryProject`, {}),

    createProjectAndProjectOrder: (
      companyId: number
    ): Promise<ProjectOrderCreateResponseModel> =>
      BuyerApiClient.post(`${companyId}/BuyerProjects/PostOrderProject`, {}),

    updateProjectName: (
      companyId: number,
      projectId: number,
      name: { name: string }
    ): Promise<ProjectOrderCreateResponseModel> =>
      BuyerApiClient.put(
        `${companyId}/BuyerProjects/${projectId}/UpdateName`,
        name
      ),
  },

  buyerDealsGroup: {
    updateInquiryRowTargetPrice: (
      companyId: number,
      dealGroupId: number,
      prices: InquiryUpdateTargetPriceModel[]
    ): Promise<ProjectOrderCreateResponseModel> =>
      BuyerApiClient.put(
        `${companyId}/BuyerDealGroups/${dealGroupId}/updateInquiryRowTargetPrices`,
        prices
      ),

    updateOfferDeadlineDate: (
      companyId: number,
      dealGroupId: number,
      date: string
    ): Promise<ProjectOrderCreateResponseModel> =>
      BuyerApiClient.put(
        `${companyId}/BuyerDealGroups/${dealGroupId}/UpdateOfferDeadlineDate`,
        { offerDeadlineDate: date }
      ),

    sendInquiryUpdateEmails: (
      companyId: number,
      dealGroupId: number,
      supplierIds: number[]
    ): Promise<void> =>
      BuyerApiClient.post(
        `${companyId}/BuyerDealGroups/${dealGroupId}/sendInquiryUpdatedEmails`,
        {
          supplierIds,
          documentUrlTemplate: `${
            window && window.location.origin
          }/external-seller-inquiry?accessToken={accessToken}`,
        }
      ),

    sendAdditionalInquiries: (
      companyId: number,
      dealGroupId: number,
      suppliers: ProjectInquiryAddRemoveSupplierModel[]
    ): Promise<InquiryGroupAdditionalInquiriesResponseModel[]> =>
      BuyerApiClient.post(
        `${companyId}/BuyerDealGroups/${dealGroupId}/sendAdditionalInquiries`,
        {
          suppliers,
          documentUrlTemplate: `${
            window && window.location.origin
          }/external-seller-inquiry?accessToken={accessToken}`,
        }
      ),
  },
};
